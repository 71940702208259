/* eslint-disable @typescript-eslint/no-explicit-any */

import { AdobeTagManager, type DigitalData } from '@dx-ui/config-metrics';
import { useState } from 'react';
import { env } from '@dx-ui/framework-env';
import { createMetrics, metricsConfig } from '@dx-ui/config-metrics';

const isBrowser = typeof window !== 'undefined';

export const digitalData: DigitalData = {
  event: [],
  page: {
    attributes: {
      expType: 'New Hilton',
      siteExperience: 'R',
    },
    category: {
      experience: 'Browser',
      siteType: 'B',
    },
    pageInfo: {},
  },
  user: [],
};

function isMetricsDisabled() {
  if (!env('TRACKING_SEED_FILE')) {
    return true;
  }
  if (isBrowser) {
    const localStorageItem = window.localStorage.getItem('OHW_PROP_disable_metrics');
    if (localStorageItem) {
      try {
        return JSON.parse(localStorageItem);
      } catch {
        return false;
      }
    }
  }
  return false;
}

type TUserDataProps = {
  hhonorsNumber: string;
  tierName: string;
  points: string;
  goUserTypes: string[] | null;
};

export type Metrics = {
  trackUserLoggedIn: (props: TUserDataProps[]) => void;
  trackEvent: (
    eventName: string,
    params?: Record<string, unknown> | null,
    shouldAddEvent?: boolean
  ) => void;
};
type MetricsInstance = Partial<Metrics>;

type Constructor<T> = new (...args: any[]) => T;
type AdobeInterface = typeof AdobeTagManager;
export interface ExtendedAdobeTagManager extends AdobeInterface, Metrics {}

export function extendAdobeTagManager<Base extends Constructor<AdobeTagManager>>(
  Base: Base
): ExtendedAdobeTagManager {
  return class ExtendedAdobeTagManager extends Base {
    public trackUserLoggedIn({ hhonorsNumber, tierName, points, goUserTypes }: TUserDataProps) {
      if (hhonorsNumber) {
        this['_set']('user[0].profile[0].profileInfo.profileID', hhonorsNumber);
        this['_set']('user[0].profile[0].profileInfo.pointsBalance', points);
        this['_set']('user[0].profile[0].profileInfo.rewardsTier', tierName);
        this['_set']('user[0].profile[0].attributes.loginStatus', 'Logged-in');

        if (goUserTypes && goUserTypes.length) {
          this['_set']('user[0].profile[0].attributes.goUserType', goUserTypes.join(','));
        } else {
          this['_set']('user[0].profile[0].attributes.goUserType', '');
        }
      } else {
        this['_set']('user[0].profile[0].profileInfo.profileID', '');
        this['_set']('user[0].profile[0].profileInfo.pointsBalance', '');
        this['_set']('user[0].profile[0].profileInfo.rewardsTier', '');
        this['_set']('user[0].profile[0].attributes.goUserType', '');
        this['_set']('user[0].profile[0].attributes.loginStatus', 'Logged-out');
      }
      this.trackEvent('trackUserLoggedIn', null, true);
    }

    public trackEvent(
      eventName: string,
      params: Record<string, unknown> | null = null,
      shouldAddEvent = false
    ) {
      void this['track'](eventName, params, shouldAddEvent);
      if (!shouldAddEvent) {
        this['_resetEvents']();
      }
    }
  } as any;
}

export function useMetrics(): MetricsInstance {
  const [{ api: metricsInstance }, _] = useState(() =>
    createMetrics(
      metricsConfig({
        debug: /local|test/.test(env('APP_ENV')),
        Interface: extendAdobeTagManager(AdobeTagManager),
        seedFile: isMetricsDisabled() ? '' : env('TRACKING_SEED_FILE'),
        server: !isBrowser,
        digitalData,
      })
    )
  );

  return metricsInstance;
}

import { makePageWrapper } from '../../components/StaticPageRenderer';
import { makeGetServerSideProps } from '../../utils/static-pages';
import GlobalPrivacyContent from '../../components/GlobalPrivacyContent';

export const getServerSideProps = makeGetServerSideProps();

export default makePageWrapper(GlobalPrivacyContent, {
  seo: {
    hideSearchEngine: false,
    metaDescription:
      "Hilton's pledge is to deliver the highest level of customer service, which includes respecting your privacy and protecting your personal information.",
    metaKeywords:
      'hotels,hotel reservations,hotel discount,leisure hotels,business hotels,www.hilton.com,Hilton Hotels & Resorts',
    pageHeading: null,
    pageTitle: 'Hilton Global Privacy Statement',
  },
});
